<template>
  <div style="width: 1010px">
    <div style="overflow: hidden">
      <el-form :model="form" ref="form" :rules="rules" label-width="80px">
        <div class="t">歌单信息</div>
        <el-row>
          <el-col :span="24">
            <el-form-item label="歌单封面" prop="image.url">
              <div class="upload-box">
                <div class="image">
                  <img v-if="form.image.url" :src="form.image.url" />
                  <img v-else src="../../assets/upload1.png" />
                </div>
                <div>
                  <div class="tip">
                    <span>格式：支持格式为JPG、PNG </span>
                    <span>大小：小于8M</span>
                  </div>
                  <el-upload
                    accept="image/jpeg,image/png"
                    v-loading="upFMLoading"
                    class="upload1"
                    action="/api/oss/upload"
                    :on-change="changeFMUpload"
                    :auto-upload="false"
                    :on-success="upFM"
                    :before-upload="beforeUpFM"
                    :on-error="resetUpFMLoading"
                    :show-file-list="false"
                    ref="FM"
                  >
                    <div class="el-upload__text">上传图片</div>
                  </el-upload>
                </div>
              </div>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item label="歌单名称" prop="songsName">
              <el-input
                class="diy-input"
                v-model="form.songsName"
                placeholder="请输入歌单名称"
                maxlength="50"
                show-word-limit
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item label="歌单简介" prop="songsInfo">
              <el-input
                class="diy-textarea"
                placeholder="请输入10-500字"
                type="textarea"
                :maxlength="500"
                :autosize="{ minRows: 2 }"
                v-model="form.songsInfo"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="歌单类型" prop="songsType">
              <el-select
                popper-class="diy-popper"
                class="diy-select"
                v-model="form.songsType"
                placeholder="请选择歌单类型"
                style="width: 100%"
              >
                <el-option
                  v-for="(item, index) in songsTypes"
                  :key="index"
                  :value="item.name"
                  >{{ item.name }}</el-option
                >
              </el-select>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item label="发布时间" prop="time">
              <el-date-picker
                class="diy-input"
                popper-class="diy-date-picker"
                style="width: 100%"
                v-model="form.time"
                type="date"
                value-format="yyyy-MM-dd hh:mm:ss"
                placeholder="选择发布日期"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>
    <div style="padding-left: 80px">
      <com-button class="submit" @click="submit">发布</com-button>
    </div>
    <!--     vueCropper 剪裁图片实现-->
    <el-dialog title="图片剪裁" :visible.sync="dialogVisible" @close="upFMLoading = false" append-to-body>
      <div class="cropper-content">
        <div class="cropper" style="text-align: center">
          <vueCropper
            ref="cropper"
            :img="option.img"
            :outputSize="option.size"
            :outputType="option.outputType"
            :info="true"
            :full="option.full"
            :canMove="option.canMove"
            :canMoveBox="option.canMoveBox"
            :original="option.original"
            :autoCrop="option.autoCrop"
            :fixed="option.fixed"
            :fixedNumber="option.fixedNumber"
            :centerBox="option.centerBox"
            :infoTrue="option.infoTrue"
            :fixedBox="option.fixedBox"
          ></vueCropper>
        </div>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false,upFMLoading = false">取 消</el-button>
        <el-button type="primary" @click="finish" :loading="dialogLoading"
          >确认</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>
<script>
import comButton from "@/components/com/com-button";
import { getAudioDuration } from "@/util/util";
import { upYP, upFM } from "@/mixin/upload";
export default {
  mixins: [upYP, upFM],
  components: {
    comButton,
  },
  computed: {
    types() {
      return this.$store.state.com.headerList || [];
    },
    singleTypes() {
      return this.types;
      // return this.types.filter((item) => ~~item.type === 1);
    },
    songsTypes() {
      return this.types;
      // return this.types.filter((item) => ~~item.type === 2);
    },
    userInfo() {
      return this.$store.state.user.userInfo;
    },
    isUpSongs() {
      return this.$route.query.isUpSongs == "1" ? true : false;
    },
    songsName() {
      return this.$route.query.songsName;
    },
    songsID() {
      return this.$route.query.id;
    },
  },
  watch: {
    isUpSongs: {
      immediate: true,
      handler(bool) {
        if (!bool) return;
        console.log(this.songsName);
        this.$api.songsDetail({ id: this.songsID }).then((resolve) => {
          this.form.image.url = resolve.data.cover;
          this.form.songsName = resolve.data.name;
          this.form.songsType = resolve.data.listTypeName;
          this.form.time = resolve.data.releaseTime;
          this.form.songsInfo = resolve.data.introduce;
        });
      },
    },
  },

  data() {
    return {
      dialogVisible: false,
      // 裁剪组件的基础配置option
      option: {
        img: "", // 裁剪图片的地址
        info: true, // 裁剪框的大小信息
        outputSize: 1, // 裁剪生成图片的质量
        outputType: "jpeg", // 裁剪生成图片的格式
        canScale: false, // 图片是否允许滚轮缩放
        autoCrop: true, // 是否默认生成截图框
        autoCropWidth: 200, // 默认生成截图框宽度
        autoCropHeight: 200, // 默认生成截图框高度
        fixedBox: false, // 固定截图框大小 不允许改变
        fixed: true, // 是否开启截图框宽高固定比例
        fixedNumber: [1, 1], // 截图框的宽高比例
        full: true, // 是否输出原图比例的截图
        canMoveBox: true, // 截图框能否拖动
        original: false, // 上传图片按照原始比例渲染
        centerBox: true, // 截图框是否被限制在图片里面
        infoTrue: true, // true 为展示真实输出图片宽高 false 展示看到的截图框宽高
      },
      dialogLoading:false,
      form: {
        songsName: "",
        time: "",
        songsInfo: "",
        songsType: "",
        singleName: "",
        singer: "",
        singleInfo: "",
        singleTime: "",
        singleType: "",
        image: {
          id: "",
          url: "",
        },
        yp: {
          localUrl: "",
          url: "",
          name: "",
          duration: "",
        },
      },
      rules: {
        songsName: [
          { required: true, message: "请输入歌单名称", trigger: "blur" },
          { max: 50, message: "长度在 50 个字符以内", trigger: "blur" },
        ],
        songsType: [
          { required: true, message: "请选择歌单类型", trigger: "change" },
        ],
        singleType: [
          { required: true, message: "请选择歌曲类型", trigger: "change" },
        ],
        time: [
          { required: true, message: "请选择歌单发布时间", trigger: "change" },
        ],
        singleTime: [
          { required: true, message: "请选择歌曲发布时间", trigger: "change" },
        ],
        singleName: [
          { required: true, message: "请输入歌曲名称", trigger: "change" },
          { max: 50, message: "长度在 50 个字符以内", trigger: "blur" },
        ],
        // singer: [{ required: true, message: "请输入歌手", trigger: "blur" }],
        // singleInfo: [
        //   { required: true, message: "请输入简介", trigger: "blur" },
        // ],
        // songsInfo: [{ required: true, message: "请输入简介", trigger: "blur" }],
        // "image.url": [
        //   { required: true, message: "请上传歌曲封面", trigger: "blur" },
        // ],
        "yp.url": [
          { required: true, message: "请上传歌曲音频", trigger: "blur" },
        ],
      },
      // isUpSongs: false,
    };
  },
  methods: {
    /*
     * 用户无上传图片则自动上传默认图片
     * 返回图片地址之后调用提交请求
     * */
    autoGetImageUrl() {
      this.$api
        .uploadImage(this.$store.state.config.uploadDefaultImage)
        .then((resolve) => {
          this.sendSubmit(resolve.data.url);
        })
        .catch((error) => {
          this.$message.error(error);
        });
    },
    submit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.form.yp.localUrl &&
            getAudioDuration(this.form.yp.localUrl).then((item) => {
              this.form.yp.duration = item;
              // this.sendSubmit();
            });
          this.sendSubmit();
        } else {
          console.log(valid, "error submit!!");
          return false;
        }
      });
    },
    //提交请求
    sendSubmit(imageUrl) {
      let {
        songsName,
        songsInfo,
        image,
        time,
        // yp,
        // singleName,
        // singleInfo,
        // singer,
        songsType,
        // singleType,
      } = this.form;
      let songsTypeItem = this.songsTypes.find(
        (item) => item.name === songsType
      );
      // let singleTypeItem = this.singleTypes.find(
      //   (item) => item.name === singleType
      // );
      let songsTypeID = songsTypeItem && songsTypeItem.id;
      // let singleTypeID = singleTypeItem && singleTypeItem.id;
      let params = {};
      if (!imageUrl && !image.url) {
        this.autoGetImageUrl();
        return;
      } else {
        imageUrl = image.url || imageUrl;
      }
      let list = {
        name: songsName,
        introduce: songsInfo,
        typeId: songsTypeID,
        cover: imageUrl,
        releaseTime: time,
      };
      if (!this.isUpSongs) {
        // params.music = {
        //   name: singleName,
        //   introduce: singleInfo,
        //   typeId: singleTypeID,
        //   url: yp.url,
        //   singer: singer,
        //   cover: imageUrl,
        //   releaseTime: time,
        // };
        params.list = list;
        this.$api.uploadSongs(list).then((resolve) => {
          console.log(resolve);
          if (resolve.code === 200) {
            this.$message.success("提交成功");
            this.$router.push({ query: { songsName: "" } });
            this.$store.commit("SET_ROUTER_KEY"); //刷新页面
          }
        });
      } else {
        params = { ...list };
        params.id = this.songsID;
        this.$api.upSongs(params).then((resolve) => {
          if (resolve.code === 200) {
            this.$message.success("提交成功");
            this.$router.push("./personal");
          }
        });
      }
    },
  },
  mounted() {
    // this.form.songsName = this.$route.query.songsName || "";
  },
};
</script>
<style lang="scss">
@import "../../style/form";
</style>
<style scoped lang="scss">
.t {
  margin-top: 28px;
  margin-bottom: 50px;
  font-size: 28px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #333333;
}
.submit {
  margin: 50px auto;
}

</style>
