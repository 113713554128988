<template>
  <div class="upload-container">
    <div class="left">
      <template v-for="(item, index) in leftList">
        <div
          v-if="!up || currentIndex === index"
          :key="index"
          :class="{ current: currentIndex === index }"
          @click="!up && $router.push(`/upload?type=${index}`)"
        >
          <img v-if="currentIndex === index" :src="item.icon" />
          <img v-else :src="item.icon1" />
          {{ up && songsName ? item.name3 : up ? item.name2 : item.name }}
        </div>
      </template>
    </div>
    <div class="right">
      <!--      <div class="right-title">欢迎{{ up ? "编辑" : "上传" }}音乐作品</div>-->
      <div class="right-title">
        {{ leftList[currentIndex].title }}
      </div>
      <form-songs :key="up" v-if="currentIndex === 0"></form-songs>
      <form-single :key="up" v-if="currentIndex === 1"></form-single>
    </div>
  </div>
</template>
<script>
import formSingle from "@/components/form/form-single";
import formSongs from "@/components/form/form-songs";
export default {
  data() {
    return {
      currentIndex: 0,
      leftList: [
        {
          name: "创建歌单",
          name2: "编辑歌单",
          icon: require("../assets/upicon1.png"),
          icon1: require("../assets/upicon11.png"),
          title: "欢迎创建歌单",
        },
        {
          name: "上传音乐",
          name2: "编辑歌曲",
          name3: "上传音乐",
          title: "欢迎上传音乐作品",
          icon: require("../assets/upicon2.png"),
          icon1: require("../assets/upicon22.png"),
        },
      ],
      up: null,
      songsName: null,
    };
  },
  watch: {
    "$store.state.user.userInfo": {
      handler(v) {
        !v && this.$router.push("/");
      },
    },
    $route: {
      immediate: true,
      handler() {
        Object.assign(this._data, this.$options.data());
        let up = this.$route.query.up;
        let type = this.$route.query.type;
        this.songsName = this.$route.query.songsName;
        // let id = this.$route.query.id;

        switch (up) {
          case "single":
            this.up = up;
            this.currentIndex = 1;
            break;
          case "songs":
            this.up = up;
            this.currentIndex = 0;
            break;
          default:
            this.currentIndex = type ? parseInt(type) : 0;
        }
      },
    },
  },

  components: {
    formSingle,
    formSongs,
  },
};
</script>
<style scoped lang="scss">
.upload-container {
  margin-top: 68px;
  display: flex;
  align-items: flex-start;
  .left {
    width: 200px;
    box-shadow: 0px 3px 4px 0px #f0f0f0;
    margin-right: 86px;
    border-radius: 2px;
    overflow: hidden;
    > div {
      font-size: 18px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #333333;
      height: 55px;
      line-height: 55px;
      padding-left: 30px;
      box-sizing: border-box;
      position: relative;
      display: flex;
      align-items: center;
      cursor: pointer;
      img {
        width: 20px;
        margin-right: 30px;
      }
      //&:hover{
      //  color: #fff;
      //  background: #C20C0C;
      //}
    }
    > div.current {
      background: #c20c0c;
      //border-radius: 2px 2px 0px 0px;
      color: #fff;
    }
    //> div.current:after {
    //  content: "";
    //  position: absolute;
    //  left: 0px;
    //  width: 4px;
    //  height: 55px;
    //  background: linear-gradient(139deg, #c20c0c 0%, #d27c7c 100%);
    //  border-radius: 2px;
    //}
  }
  .right-title {
    font-size: 32px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #333333;
    padding-bottom: 32px;
  }
}
</style>
