/*
 * 通过音频地址获取音频时长
 * */
export const getAudioDuration = (url) => {
  return new Promise((resolve) => {
    const audio = document.createElement("audio");
    audio.src = url;
    audio.muted = true;
    audio.play().then(() => {
      let duration = audio.duration;
      audio.src = "";
      audio.remove();
      resolve(duration);
    });
  });
};

/*
 * 随机返回数组的部分数据
 * */
export const randomBackPart = (data, resNum) => {
  let res = [];
  let arr = [...data];
  while (resNum && arr.length !== 0) {
    let i = randomNum(0, arr.length - 1);
    let d = arr.splice(i, 1);
    res.push(...d);
    resNum--;
  }
  return res;
};

//生成从minNum到maxNum的随机数
export const randomNum = (minNum, maxNum) => {
  if (minNum === maxNum) return minNum;
  return parseInt(Math.random() * (maxNum - minNum + 1) + minNum, 10);
};
