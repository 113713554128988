<template>
  <div style="width: 1010px">
    <div class="t">歌曲信息</div>
    <div style="overflow: hidden">
      <el-form :model="form" ref="form" :rules="rules" label-width="80px">
        <el-row>
          <el-col :span="24">
            <el-form-item label="歌曲名称" prop="singleName">
              <el-input
                class="diy-input"
                v-model="form.singleName"
                placeholder="请输入歌曲名称"
                maxlength="50"
                show-word-limit
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item label="歌曲封面" prop="image.url">
              <div class="upload-box">
                <div class="image">
                  <img v-if="form.image.url" :src="form.image.url" />
                  <img v-else src="../../assets/upload1.png" />
                </div>
                <div>
                  <div class="tip">
                    <span>格式：支持格式为JPG、PNG </span>
                    <span>大小：小于8M</span>
                  </div>
                  <el-upload
                    accept="image/jpeg,image/png"
                    v-loading="upFMLoading"
                    class="upload1"
                    action="/api/oss/upload"
                    :on-change="changeFMUpload"
                    :auto-upload="false"
                    :on-success="upFM"
                    :before-upload="beforeUpFM"
                    :on-error="resetUpFMLoading"
                    :show-file-list="false"
                    ref="FM"
                  >
                    <div class="el-upload__text">上传图片</div>
                  </el-upload>
                </div>
              </div>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item label="上传歌曲" prop="yp.url">
              <div class="upload-box">
                <div class="image">
                  <img alt="" src="../../assets/upload2.png" />
                </div>
                <div>
                  <div class="tip" v-if="form.yp.name">
                    <span v-if="form.yp.name">
                      {{ form.yp.name }}
                    </span>
                    <span></span>
                  </div>
                  <div class="tip" v-else>
                    <span>格式：支持格式为MP3、M4A</span>
                    <span>大小：小于30M</span>
                  </div>

                  <el-upload
                    accept="audio/x-m4a,audio/mpeg,audio/mp4,audio/mp3"
                    v-loading="upYPLoading"
                    class="upload1"
                    action="/api/oss/upload"
                    :on-success="upYP"
                    :before-upload="beforeUpYP"
                    :on-error="errUpYPLoading"
                    :show-file-list="false"
                  >
                    <div class="el-upload__text">上传音频</div>
                  </el-upload>
                </div>
                <div class="uploadStatus">
                  <span class="s" v-if="form.yp.status === 'success'"
                    ><img src="../../assets/ok.png" />上传成功</span
                  >
                  <span class="e" v-if="form.yp.status === 'error'"
                    ><img src="../../assets/err.png" />上传失败</span
                  >
                </div>
              </div>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="20">
          <el-col :span="singleId ? 24 : 12">
            <el-form-item label="歌曲类别" prop="type">
              <el-select
                popper-class="diy-popper"
                class="diy-select"
                v-model="form.type"
                placeholder="请选择歌曲类型"
                style="width: 100%"
              >
                <el-option
                  v-for="(item, index) in types"
                  :key="index"
                  :value="item.name"
                  >{{ item.name }}</el-option
                >
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="选择歌单" v-if="!singleId" prop="songs">
              <el-select
                popper-class="diy-popper"
                class="diy-select"
                v-model="form.songs"
                placeholder="请选择歌单"
                style="width: 100%"
              >
                <el-option
                  v-for="(item, index) in songsList"
                  :key="index"
                  :label="item.name"
                  :value="item.id"
                  >{{ item.name }}</el-option
                >
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item label="发布时间" prop="time">
              <el-date-picker
                class="diy-input"
                popper-class="diy-date-picker"
                style="width: 100%"
                v-model="form.time"
                type="date"
                value-format="yyyy-MM-dd hh:mm:ss"
                placeholder="选择发布日期"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item label="歌手" prop="singer">
              <el-input
                class="diy-input"
                v-model="form.singer"
                placeholder="请输入歌手"
                :maxlength="50"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item label="歌曲简介">
              <el-input
                class="diy-textarea"
                type="textarea"
                placeholder="请输入10-500字"
                :maxlength="500"
                :autosize="{ minRows: 2 }"
                v-model="form.info"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>
    <div style="padding-left: 80px">
      <com-button class="submit" @click="submit">发布</com-button>
    </div>
    <!--     vueCropper 剪裁图片实现-->
    <el-dialog title="图片剪裁" :visible.sync="dialogVisible" @close="upFMLoading = false"  append-to-body>
      <div class="cropper-content">
        <div class="cropper" style="text-align: center">
          <vueCropper
            ref="cropper"
            :img="option.img"
            :outputSize="option.size"
            :outputType="option.outputType"
            :info="true"
            :full="option.full"
            :canMove="option.canMove"
            :canMoveBox="option.canMoveBox"
            :original="option.original"
            :autoCrop="option.autoCrop"
            :fixed="option.fixed"
            :fixedNumber="option.fixedNumber"
            :centerBox="option.centerBox"
            :infoTrue="option.infoTrue"
            :fixedBox="option.fixedBox"
          ></vueCropper>
        </div>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false,upFMLoading = false">取 消</el-button>
        <el-button type="primary" @click="finish" :loading="dialogLoading"
        >确认</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>
<script>
import comButton from "@/components/com/com-button";
import { getAudioDuration } from "@/util/util";
import { upYP, upFM } from "@/mixin/upload";
import { Songs } from "@/class/music";
export default {
  mixins: [upYP, upFM],
  components: {
    comButton,
  },
  data() {
    return {
      dialogVisible: false,
      // 裁剪组件的基础配置option
      option: {
        img: "", // 裁剪图片的地址
        info: true, // 裁剪框的大小信息
        outputSize: 1, // 裁剪生成图片的质量
        outputType: "jpeg", // 裁剪生成图片的格式
        canScale: false, // 图片是否允许滚轮缩放
        autoCrop: true, // 是否默认生成截图框
        autoCropWidth: 200, // 默认生成截图框宽度
        autoCropHeight: 200, // 默认生成截图框高度
        fixedBox: false, // 固定截图框大小 不允许改变
        fixed: true, // 是否开启截图框宽高固定比例
        fixedNumber: [1, 1], // 截图框的宽高比例
        full: true, // 是否输出原图比例的截图
        canMoveBox: true, // 截图框能否拖动
        original: false, // 上传图片按照原始比例渲染
        centerBox: true, // 截图框是否被限制在图片里面
        infoTrue: true, // true 为展示真实输出图片宽高 false 展示看到的截图框宽高
      },
      dialogLoading:false,
      songsList: [],
      form: {
        singleName: "",
        songs: "",
        type: "",
        time: "",
        singer: "",
        info: "",
        image: {
          id: "",
          url: "",
        },
        yp: {
          localUrl: "",
          url: "",
          name: "",
          duration: "",
        },
      },
      rules: {
        singleName: [
          { required: true, message: "请输入歌曲名称", trigger: "blur" },
          { max: 50, message: "长度在 50 个字符以内", trigger: "blur" },
        ],
        // songs: [{ required: true, message: "请选择歌单", trigger: "change" }],
        type: [
          { required: true, message: "请选择歌曲分类", trigger: "change" },
        ],
        time: [
          { required: true, message: "请选择歌曲发布时间", trigger: "change" },
        ],
        // singer: [{ required: true, message: "请输入歌手", trigger: "blur" }],
        // "image.url": [
        //   { required: true, message: "请上传歌曲封面", trigger: "blur" },
        // ],
        "yp.url": [
          { required: true, message: "请上传歌曲音频", trigger: "blur" },
        ],
      },
    };
  },
  computed: {
    types() {
      // return this.$store.state.com.headerList;
      let types = this.$store.state.com.headerList || [];
      return types.filter((item) => ~~item.type === 1);
    },
    userInfo() {
      return this.$store.state.user.userInfo;
    },
    singleId() {
      return this.$route.query.id || false;
    },
  },
  watch: {
    "$route.query.id": {
      immediate: true,
      handler(id) {
        if (!id) return;
        this.$api.singleDetail({ musicId: id }).then((resolve) => {
          this.form.singleName = resolve.data.musicName;
          this.form.type = resolve.data.musicTypeName;
          // this.form.time = resolve.data.musicReleaseTime.replace(/T/g, " ");
          this.form.time = resolve.data.releaseTime.replace(/T/g, " ");
          this.form.singer = resolve.data.singer;
          // this.form.info = resolve.data.musicIntroduce;
          this.form.info = resolve.data.introduce;
          // this.form.image.url = resolve.data.musicCover;
          this.form.image.url = resolve.data.cover;
          this.form.yp.url = resolve.data.url;
          this.form.yp.localUrl = resolve.data.url;
          this.form.yp.name = "." + resolve.data.format;
          // this.form.songs = resolve.data.listId;
          this.logId = resolve.data.id;
        });
      },
    },
  },
  methods: {
    /*
     * 用户无上传图片则自动上传默认图片
     * 返回图片地址之后调用提交请求
     * */
    autoGetImageUrl() {
      this.$api
        .uploadImage(this.$store.state.config.uploadDefaultImage)
        .then((resolve) => {
          this.sendSubmit(resolve.data.url);
        })
        .catch((error) => {
          this.$message.error(error);
        });
    },

    submit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          let ypUrl = this.form.yp.localUrl || this.form.yp.url;
          ypUrl &&
            getAudioDuration(ypUrl).then((item) => {
              this.form.yp.duration = item;
              this.sendSubmit();
            });
        } else {
          console.log(valid, "error submit!!");
          return false;
        }
      });
    },
    //提交请求
    sendSubmit(imageUrl) {
      let { singleName, info, singer, time, image, yp, songs, type } =
        this.form;

      // let songsID = this.songsList.find(
      //   (item) => item.name.trim() === songs.trim()
      // );

      // songsID =songsID && songsID.id
      let typeID = this.types.find((item) => item.name === type).id;

      if (!imageUrl && !image.url) {
        this.autoGetImageUrl();
        return;
      } else {
        imageUrl = image.url || imageUrl;
      }

      let params = {
        music: {
          name: singleName, //歌名
          introduce: info, //歌曲简介
          typeId: typeID, //歌曲类型
          url: yp.url, //歌曲地址
          duration: yp.duration,
          singer: singer, //歌手
          cover: imageUrl, //歌曲封面
          releaseTime: time, //发布时间
        },
        listId: songs, //歌单ID
        // "id": 1 //数据ID
      };
      // this.singleId && (params.id = ~~this.singleId);
      if (this.singleId) {
        params.music.id = ~~this.singleId;
        params.id = this.logId;
      }

      //新增提交请求
      this.$api
        .uploadSingle(params)
        .then((resolve) => {
          if (resolve.code === 200) {
            this.$message.success("提交成功");
            this.$store.commit("SET_ROUTER_KEY"); //刷新页面
          }
        })
        .catch(() => this.$message.error("保存失败"));
    },
    sendGetsongsList() {
      // console.log(this.userInfo);
      this.$api
        .songsList({ userId: this.userInfo.id, size: 300 })
        .then((resolve) => {
          let data = resolve.data.records;
          this.songsList = data.map((item) => new Songs(item));
        });
    },
  },
  mounted() {
    if (this.$route.query.songsId)
      this.form.songs = ~~this.$route.query.songsId;
    this.sendGetsongsList();
  },
};
</script>
<style scoped lang="scss">
@import "../../style/form";
.t {
  margin-top: 28px;
  margin-bottom: 50px;
  font-size: 28px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #333333;
}
.submit {
  margin: 50px auto;
}
</style>
