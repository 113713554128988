

export const upYP = {
  data() {
    return {
      upYPLoading: false,
    };
  },
  methods: {
    resetUpYPLoading() {
      this.upYPLoading = false;
    },
    errUpYPLoading(err) {
      this.upYPLoading = false;
      this.$message.error("未知错误");
      console.log(err);
    },
    upYP(res, file) {
      this.resetUpYPLoading();
      if (res.code === 200) {
        this.form.yp.status = "success";
        let { name, url } = res.data;
        this.form.yp.url = url;
        this.form.yp.name = name;
        this.form.yp.localUrl = URL.createObjectURL(file.raw);
        this.$refs.form.validateField("yp.url");
      } else {
        this.$message.error(res.msg);
        this.form.yp.status = "error";
      }
    },
    beforeUpYP(file) {
      // this.form.yp.status = "";
      this.$set(this.form.yp,'status','')
      console.log(file)
      const isM4A = file.type === "audio/x-m4a" || file.type === "audio/mp4";
      const isMP3 = file.type === "audio/mpeg" || file.type === "audio/mp3";
      const isLt30M = file.size / 1024 / 1024 < 30;
      if (!isM4A && !isMP3) {
        this.$message.error("上传音频文件只能是 MP3或M4A 格式!");
        return false;
      } else if (!isLt30M) {
        this.$message.error("上传音频文件大小不能超过 30MB!");
        return false;
      } else {
        this.upYPLoading = true;
        return true;
      }

      // if ((isM4A || isMP3) && isLt30M) {
      //   this.upYPLoading = true;
      // }
      // return (isM4A || isMP3) && isLt30M;
    },
  },
};

export const upFM = {
  data() {
    return {
      upFMLoading: false
    };
  },
  methods: {
    resetUpFMLoading() {
      this.upFMLoading = false;
    },
    upFM(res) {
      this.resetUpFMLoading();
      if (res.code === 200) {
        let { name, url } = res.data;
        this.form.image.url = url;
        this.form.image.name = name;
        this.$refs.form.validateField("image.url");
      } else {
        this.$message.error(res.msg);
      }
    },
    changeFMUpload(file) {
      // 上传成功后将图片地址赋值给裁剪框显示图片
      console.log(file)
      if(this.beforeUpFM(file.raw)){
        this.$nextTick(() => {
          this.option.img = URL.createObjectURL(file.raw);
          this.dialogVisible = true;
        });
      }
    },
    finish() {
      // 获取截图的 blob 数据
      this.$refs.cropper.getCropData((blob) => {
        this.loading = true
        this.dialogVisible = false
        this.$api.uploadImage(blob).then(resolve=>{
          this.upFM(resolve)
          // console.log(resolve)
          // this.form.image.url = resolve.data.url
        }).catch(()=>{this.resetUpFMLoading()})
        // this.form.image.url = URL.createObjectURL(blob)
        // this.isPreview = true
      })
    },
    beforeUpFM(file) {
      console.log(file)
      const isJPG = file.type === "image/jpeg";
      const isPNG = file.type === "image/png";
      const isLt8M = file.size / 1024 / 1024 < 8;

      if (!isJPG && !isPNG) {
        this.$message.error("上传头像图片只能是 JPG或PNG 格式!");
      }
      if (!isLt8M) {
        this.$message.error("上传头像图片大小不能超过 8MB!");
      }
      if ((isJPG || isPNG) && isLt8M) {
        this.upFMLoading = true;
      }
      return (isJPG || isPNG) && isLt8M;
    },
  },
};
